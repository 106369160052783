import * as PropTypes from "prop-types"
import React from "react"

import { SetTokens, Breadcrum, PageLinks, SEO } from "../components/index";
import * as styles from './set-page.module.scss';
import { getSetBreadcrum } from '../utils/breadcrums';
import { baseSetType, pageLinkType, setTokenType } from '../types'
class SetPageTemplate extends React.PureComponent {
  renderInfoForSetType() {
    const { type, block, name, description } = this.props.pageContext.set;
    if(description) {
      return description;
    }
    const lowerCaseType = type.toLowerCase();
    switch (lowerCaseType) {
      case 'box':
        return `${name} is a box set. Box sets are those sets released in fixed packages (often one or more preconstructed decks) instead of through booster packs.`;
      case 'un':
        return `${name} is a 'Unset' - A casual Magic: the Gathering block constructed format consisting of fun cards that are not legal in any other MTG format.`;
      case 'promo':
        return null;
      case 'expansion':
        return `${name} is an expansion set in the ${block} block. An expansion set in Magic: The Gathering is a pool of cards released together and designed for play in the same environment. Standard cards and tokens in a set can be obtained at random through booster packs. Sometimes a set will contain promo tokens that are given away as prizes at Friday Night Magic (FNM) or special pre-release events.`
      case 'core':
        return `${name} is a core sets. Core sets typically form the base set of cards for standard tournament play and rotations. All core sets through Tenth Edition consisted solely of reprinted cards and tokens but as of Magic 2010, they feature new cards and tokens alongside reprints.`;
      case 'duel deck':
        return `${name} is a duel deck. The pack contains two 60 card Magic: the Gathering decks designed and developed specifically to be played against each other. Duel Decks were started in 2007 with the first Elves vs. Goblins decks.`;
      case 'commander':
        return `${name} is a commander set. The Commander sets are a line of products created for the Commander (or Elder Dragon Highlander EDH) format. From 2013, a Commander set was released yearly, each set containing five decks and the required token cards for each deck.`;
      case 'reprint':
        return `${name} is a reprint set. A reprint is a card in a set that has previously appeared in another set. Reprint sets are sets which contain only reprints. The Ice Age expansion set was the first to contain reprints.`;
      case 'conspiracy':
        return `Conspiracy is a multiplayer-focused Magic booster set designed to be drafted.`;
      default:
        return null;
    }
  }
  getSetType() {
    const { type } = this.props.pageContext.set;
    const lowerCaseType = type.toLowerCase();
    switch (lowerCaseType) {
      case 'box':
        return `box set`;
      case 'promo':
        return 'promotion';
      case 'core':
        return 'core set'
      case 'duel deck':
        return 'duel deck'
      default:
        return 'set';
    }
  }
  render() {
    const {
      pageContext: {
        set,
        set: {
          name,
          id,
          releaseDate,
          tokens
        },
        ...rest
      }
    } = this.props;
    const breadcrumPages = getSetBreadcrum({ name, id, active: true })
    const info = this.renderInfoForSetType();
    const setType = this.getSetType();
    return (
      <>
        <SEO
          title={`The ${tokens.length} Token cards of ${name} (${id})`}
          description={`The MTG ${setType} ${name} contains ${tokens.length} Token cards. It was released on ${releaseDate}. View all of ${id}'s tokens.`}
          keywords={[id, name, 'MTG set', setType, 'release']}
          pageUrl={`/tokens/${id}/`}
        />
        <Breadcrum pages={breadcrumPages} />
        <header className={styles.header}>
          <h1>The Tokens of {name} <span className={styles.secondary}>({id.toUpperCase()})</span></h1>
          <p>The Magic: the Gathering <em>(MTG)</em> {setType} {name} ({id.toUpperCase()}) was released on {releaseDate}. It includes <span className="big">{tokens.length}</span> token card{tokens.length > 1 ? 's' : ''}.</p>
          {
            info && <p>{info}</p>
          }
        </header>
        <SetTokens set={set} />
        <PageLinks {...rest} />
      </>
    )
  }
}

SetPageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    set: PropTypes.shape({
      ...baseSetType,
      tokens: PropTypes.arrayOf(PropTypes.shape({
        ...setTokenType
      }))
    }),
    ...pageLinkType,
  }).isRequired
}

export default SetPageTemplate